<template>
  <b-sidebar no-header v-model="show" backdrop shadow right class="sidebar-edit-proposal" @change="hasClosedSidebar" ref="sidebar_edit_proposal">
    <div class="p-1">
      <div class="d-flex justify-content-end">
        <feather-icon 
          icon="XIcon" 
          size="20" 
          class="close-sidebar-icon"
          @click="show = false"
        />
      </div>
    </div>
    <step-cover-image
      v-if="section === 'cover'"
      :is_editing="true"
      @close_sidebar="show = false"
      @update_campaign="updateCampaign"
    ></step-cover-image>

    <step-title
      v-if="section === 'title'"
      @update_campaign="updateCampaign"
    >
    </step-title>

    <step-organization
      v-if="section === 'brand'"
      @update_campaign="updateCampaign"
    >
    </step-organization>

    <step-description
      v-if="section === 'description'"
      @update_campaign="updateCampaign"
    >
    </step-description>

    <step-service-options
      v-if="section === 'platforms'"
      @update_campaign="updateCampaign"
    >
    </step-service-options>

    <step-delivery-deadline
      v-if="section === 'deadline'"
      @update_campaign="updateCampaign"
    >
    </step-delivery-deadline>

    <step-visibility
      v-if="section === 'visibility'"
      @update_campaign="updateCampaign"
    >
    </step-visibility>

    <step-location
      v-if="section === 'location'"
      @update_campaign="updateCampaign"
    >
    </step-location>

    <step-compensation
      v-if="section === 'compensation'"
      @update_campaign="updateCampaign"
    >
    </step-compensation>

    <step-attachment-files
      v-if="section === 'attachments'"
      @update_campaign="updateCampaign"
    >
    </step-attachment-files>

    <step-information-request
      v-if="section === 'questions'"
      @update_campaign="updateCampaign"
    >
    </step-information-request>
  </b-sidebar>
</template>

<script>
import { 
  BSidebar,
} from 'bootstrap-vue';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');


export default {
  name: 'sidebarEditCastingCall',
  components: {
    BSidebar,
    stepCoverImage: () => import('@/views/pages/casting-calls/stepper/stepCoverImage.vue'),
    stepTitle: () => import('@/views/pages/casting-calls/stepper/stepTitle.vue'),
    stepOrganization: () => import('@/views/pages/casting-calls/stepper/stepOrganization.vue'),
    stepDescription: () => import('@/views/pages/casting-calls/stepper/stepDescription.vue'),
    stepServiceOptions: () => import('@/views/pages/casting-calls/stepper/stepServiceOptions.vue'),
    stepDeliveryDeadline: () => import('@/views/pages/casting-calls/stepper/stepDeliveryDeadline.vue'),
    stepVisibility: () => import('@/views/pages/casting-calls/stepper/stepVisibility.vue'),
    stepLocation: () => import('@/views/pages/casting-calls/stepper/stepLocation.vue'),
    stepCompensation: () => import('@/views/pages/casting-calls/stepper/stepCompensation.vue'),
    stepAttachmentFiles: () => import('@/views/pages/casting-calls/stepper/stepAttachmentFiles.vue'),
    stepInformationRequest: () => import('@/views/pages/casting-calls/stepper/stepInformationRequest.vue')
  },
  data() {
    return {
      show: this.open_sidebar_edit,
      section: this.section_dad
    }
  },
  props: {
    open_sidebar_edit: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      default: () => {
        return {}
      }
    },
    section_dad: {
      type: String,
    },
    data_for_sidebar: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    hasClosedSidebar() {
      this.$refs.sidebar_edit_proposal.$el.classList.remove('open-sidebar-edit-casting-call')
      if (localStorage.getItem('steps')) localStorage.removeItem('steps');
    },
    updateCampaign(campaign) {
      this.$emit('update_campaign', campaign);
      this.show = false;
      loadToastificationContent().then((module) => {
        this.$toast({
          component: module.default,
          position: 'top-right',
          props: {
            title: this.$t('creator.castingCallUpdated'),
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: this.$t('creator.proposalUpdatedText')
          }
        })
      })
    },
    changeViewConnection() {
      this.section = 'connection';
    },
    resetProposal(proposal) {
      this.$emit('reset_proposal', proposal)
      this.show = false;
    },
  },
}

</script>

<style scoped>
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
</style>
<style>
.sidebar-edit-proposal > .b-sidebar {
  width: 950px !important;
}
</style>